<template>
  <div class="px-5">
    <v-breadcrumbs
      class="pa-0 ma-0"
      :items="breadcrumbs"
      divider="-"
    ></v-breadcrumbs>

    <div>
      <FranchiseesEdit
        v-if="currentUser"
        :isCoverageFranchisee="true"
        :id="`${currentUser.franchisee.id}`"
      />
    </div>
  </div>
</template>

<script>
import FranchiseMixin from "@/mixins/franchise-style";
import FranchiseesEdit from "@/components/franchisees/FranchiseesEdit";
import moment from "moment";

export default {
  name: "CustomiseInsurance",
  components: {
    FranchiseesEdit,
  },
  data() {
    return {
      currentStep: 0,
      breadcrumbs: [
        {
          id: 1,
          text: "Customize Insurance",
          href: "#",
        },
        {
          id: 2,
          text: "Step 1",
          href: "#",
        },
      ],
    };
  },
  filters: {
    formattedExpiryDate(val) {
      return moment(val).format("DD MMMM YYYY");
    },
  },
  mixins: [FranchiseMixin],
  methods: {
    backToDashboard() {
      this.$router.push(`/user`);
    },
  },
  computed: {
    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.activeStep {
  background: red;
  color: green;
}
</style>
